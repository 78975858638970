<template>
  <header class="header_under">
    <router-link to="/">
      <div class="logo">
        <p class="name">MOG × BR蒲郡</p>
      </div>
    </router-link>
    <h1>
      <router-link to="/"
        ><img
          src="@/assets/images/common/footer_logo.svg"
          width=""
          height=""
          alt="MOG Moter Owner GrandPrix"
      /></router-link>
    </h1>
    <button class="btn_menu">
      <div
        class="navToggle"
        v-on:click="menuON = !menuON"
        v-bind:class="{ active: menuON }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p v-on:click="menuON = !menuON" class="menu_txt">MENU</p>
    </button>
    <transition v-if="loginStatus" name="fade" v-show="menuON">
      <menuLogin />
    </transition>
    <transition v-else name="fade" v-show="menuON">
      <menuLogout />
    </transition>
  </header>
</template>

<script>
import menuLogin from "@/components/menuLogin.vue";
import menuLogout from "@/components/menuLogout.vue";
export default {
  name: "HeaderSub",
  components: {
    menuLogin,
    menuLogout,
  },
  data() {
    return {
      menuON: false,
      loginStatus: true,
    };
  },
  mounted: function () {
    const flg = this.$store.getters.getIsLogedIn;
    if (flg) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_header.css" scoped></style>
